import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { Route } from 'react-router-dom'

const DefaultLayout = ({ children }) => <Fragment>{children}</Fragment>

const ProtectedRoute = props => <Route {...props} />

const AppRoute = ({ component: Component, layout: Layout, backTo, title, showLogo, ...otherProps }) => (
  <ProtectedRoute {...otherProps} render={props => (
    <Layout {...props} title={title} backTo={backTo} showLogo={showLogo}>
      <Component {...props} title={title} />
    </Layout>
  )} />
)

DefaultLayout.propTypes = {
  children: PropTypes.node.isRequired
}

ProtectedRoute.propTypes = {
  ignoreAuth: PropTypes.bool
}

AppRoute.defaultProps = {
  layout: DefaultLayout,
  backTo: null,
  title: null,
  showLogo: false
}

AppRoute.propTypes = {
  component: PropTypes.object.isRequired,
  title: PropTypes.string,
  layout: PropTypes.func,
  backTo: PropTypes.string,
  showLogo: PropTypes.bool
}

export default AppRoute
