import home from './home'
import chatbot from './chatbot'
import login from './login'

const routes = [
  ...home,
  ...chatbot,
  ...login
]

export default routes
