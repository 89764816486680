import { ACTION_USER_PERMISSIONS, ACTION_USER_PROFILE } from '../actions/actionTypes'

const initialState = {
  profile: {},
  permissions: [],
}

export const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTION_USER_PERMISSIONS:
      return {
        ...state,
        permissions: action.permissions.map(permission => permission.id)
      }
    case ACTION_USER_PROFILE:
        return {
          ...state,
          profile: action.profile
        }
    default:
      return state
  }
}
