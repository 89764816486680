import { ACTION_LOGIN, ACTION_SET_AUTHENTICATION} from '../actions/actionTypes'
import { REMEMBER_ME } from "services/Auth";

const rememberMeEnabled = localStorage.getItem(REMEMBER_ME) == 'true';

const initialState = {
  isAuthenticated: localStorage.getItem(REMEMBER_ME) ? rememberMeEnabled : false,
  token: ''
}

export const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTION_LOGIN:
      return {
        ...state,
        isAuthenticated:true,
        token: action.token
      }
    case ACTION_SET_AUTHENTICATION:
      return{
        ...state,
        isAuthenticated:true,
      }
    default:
      return state
  }
}
