import ApiService from "services/ApiService";
import {REMEMBER_ME} from "services/Auth";
import history from "./history";

const URL = process.env.REACT_APP_API_URL;
const rememberMeEnabled = localStorage.getItem(REMEMBER_ME);
class Service {
  checkStatus(status) {
    if (status === 401 && rememberMeEnabled == 'false') {
      history.push("/");
    }

    if (status === 403) {
      history.push('/');
    }

    return false;
  }

  get(path, onSuccess, onError, signal) {
    ApiService.get(path, !!signal ? {signal} : {})
        .then((response) => {
          if (response.status === 200) {
            onSuccess(response.data);
            return response.data;
          } else {
            if (!this.checkStatus(response.status)) {
              onError(response.status);
            }
          }
        })
    .catch((err) => {
      console.error(err);
        onError(err);
    });
  }

  post(path, data, onSuccess, onError) {
    ApiService.post(path, data)
    .then((response) => {
      if (response.status === 200) {
        onSuccess(response.data);
        return response.data;
      } else {
        if(!this.checkStatus(response.status)) {
          onError(response.status);
        }
      }
    })
    .catch((err) => {
      console.error(err);
      onError(err);
    });
  }

  async getWaiting(path) {
    try {
      const response = await ApiService.get(path);
      this.checkStatus(response.status);
      return response.data;
    } catch (error) {
      console.error(error);
      return error.data;
    }
  }

  async postWaiting(path, data) {
    try {
      const response = await ApiService.post(path, data);
      this.checkStatus(response.status);
      return response.data;
    } catch (error) {
      console.error(error);
      return error.data;
    }
  }

  async put(path, data) {  
    try {
      if(!data) throw new Error("Body is required");
      const response = await ApiService.put(path, data);
      return response.data;
    } catch(error) {
      return { error };
    }
  }  
}

export { URL, Service };
