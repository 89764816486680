import styled, { css } from 'styled-components'
import theme from 'commons/constants/theme'
import { ReactComponent as Loading } from '../../assets/svg/loading.svg'

const solidWhiteBackground = 'rgba(255, 255, 255, 1)'
const transparentBackground = 'rgba(255, 255, 255, .5)'

const LoadingStyled = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  color: #9e9e9e;
  width: 100%;
  height: 100%;

  ${({ full }) => full && css`
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background-color: ${(props)=>props.transparent ? transparentBackground : solidWhiteBackground};
    z-index: ${theme.zIndex.snackbar};
  `};
`

const LoadingStyledSmall = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  color: #9e9e9e;
  width: 25px;
  height: 25px;

  ${({ full }) => full && css`
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background-color: rgba(255, 255, 255, .5);
    z-index: ${theme.zIndex.snackbar};
  `};
`

const LoadingIcon = styled(Loading)`
  animation: spin 1.0s linear infinite;

  @keyframes spin {
    100% {
      transform: rotate(360deg);
    }
  }
`

export {
  LoadingStyled,
  LoadingIcon,
  LoadingStyledSmall
}
