import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import './index.css'

import App from './App'
import * as serviceWorker from './serviceWorker'
import reportError from "./commons/utils/reportError"
import { store, persistor } from 'redux/store'
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  integrations: [new BrowserTracing()],
  environment: process.env.REACT_APP_ENV,
  enabled: process.env.REACT_APP_ENV === 'prod',
  tracesSampleRate: 1.0
});

window.onerror = function(message, source, lineNum, colNum, error) {
  reportError({message, source, lineNum, colNum, error});
}

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <App />
    </PersistGate>
  </Provider>
  , document.getElementById('root')
)

serviceWorker.unregister()

console.info('AppVersion: ', process.env.REACT_APP_VERSION)