const pathAfterLogin = {
  setPath: (pathToSave, isAuthenticated) => {
    const notSavePaths = ['/login', '/']
    const shouldSave = notSavePaths.every(path => path !== pathToSave)
    if(!isAuthenticated && shouldSave){
      localStorage.setItem('pathAfterLogin', pathToSave);
    }
  },
  getPath: () => {
    const path = localStorage.getItem('pathAfterLogin');
    return path || '/home';
  },
  removePath: () =>{
    localStorage.removeItem('pathAfterLogin');
  }
}

export default pathAfterLogin;