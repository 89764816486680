import { parseISO } from "date-fns";
import { format, utcToZonedTime } from "date-fns-tz";

const debug = process.env.REACT_APP_DEBUG === "true" ? true : false;
const DB_CURSE_KEY = "DB_CURSE_KEY";

export default class Util {
  getSubDomain() {
    var subDomain = window.location.hostname.split(".");
    var clientDomain = subDomain[0];

    if (
      !clientDomain ||
      clientDomain === "localhost" ||
      clientDomain === "192"
    ) {
      clientDomain = "dev";
    }

    return clientDomain;
  }

  static debug(log) {
    if (debug) {
      console.log(log);
    }
  }
  static formatDateString(date) {
    if (!date || date === null) {
      return "";
    }
    return this.formatDateTime(new Date(date));
  }
  static formatDate(date) {
    if (!date || date === null) {
      return "";
    }
    date = new Date(date)

    return `${Util.formatNumber0(date.getDate())}/${Util.formatNumber0(date.getMonth() + 1)}/${date.getFullYear()} 
      ${Util.formatNumber0(date.getHours())}:${Util.formatNumber0(date.getMinutes())}`;
  }

  static validateHoursAndMinutes(date) {
    const now = new Date();
    let dateFixed = new Date(date);
    dateFixed.setHours(dateFixed.getHours() - 3);
    const diffMs = dateFixed - now;
    return Math.round(((diffMs % 86400000) % 3600000) / 60000);
  }

  static formatToNewDate(dateStr) {
    if (!dateStr || dateStr === null) {
      return "";
    }

    var date = new Date(dateStr);

    var _userOffset = date.getTimezoneOffset() * 60 * 1000;
    var _centralOffset = 6 * 60 * 60 * 1000;
    date = new Date(date.getTime() - _userOffset + _centralOffset);

    return date;
  }

  static calcDate(startingDate, endingDate) {
    var startDate = new Date(startingDate);
    if (!endingDate) {
      endingDate = new Date();
    }
    var endDate = endingDate;
    if (endDate.getTime() - startDate.getTime() < 0) {
      return null;
    }

    /*if (startDate > endDate) {
        var swap = startDate;
        startDate = endDate;
        endDate = swap;
    }*/

    var startYear = startDate.getFullYear();
    var february =
      (startYear % 4 === 0 && startYear % 100 !== 0) || startYear % 400 === 0
        ? 29
        : 28;
    var daysInMonth = [31, february, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

    var yearDiff = endDate.getFullYear() - startYear;
    var monthDiff = endDate.getMonth() - startDate.getMonth();
    if (monthDiff < 0) {
      yearDiff--;
      monthDiff += 12;
    }
    var dayDiff = endDate.getDate() - startDate.getDate();

    if (dayDiff < 0) {
      if (monthDiff > 0) {
        monthDiff--;
      } else {
        yearDiff--;
        monthDiff = 11;
      }
      dayDiff += daysInMonth[startDate.getMonth()];
    }

    //return yearDiff + 'Y ' + monthDiff + 'M ' + dayDiff + 'D';

    var message = "";

    message +=
      yearDiff <= 0 ? "" : yearDiff + (yearDiff > 1 ? " anos" : " ano");

    message += message && monthDiff > 0 ? ", " : "";

    message +=
      monthDiff <= 0 ? "" : monthDiff + (monthDiff > 1 ? " meses " : " mês ");

    message += message && dayDiff > 0 ? " e " : "";

    message +=
      dayDiff <= 0 ? "0" : dayDiff + (dayDiff > 1 ? " dias " : " dia ");

    return message;
  }

  static formatNumber0(number) {
    return number < 10 ? "0" + number : number;
  }
  html_substr(str, start, count) {
    var div = document.createElement("div");
    div.innerHTML = str;

    walk(div, track);

    function track(el) {
      if (count > 0) {
        var len = el.data.length;

        if (start <= len) {
          el.data = el.substringData(start, len);
          start = 0;
        } else {
          start -= len;
          el.data = "";
        }
        len = el.data.length;
        count -= len;
        if (count <= 0) {
          el.data = el.substringData(0, el.data.length + count);
        }
      } else {
        el.data = "";
      }
    }

    function walk(el, fn) {
      var node = el.firstChild;
      do {
        if (node.nodeType === 3) {
          fn(node);
        } else if (
          node.nodeType === 1 &&
          node.childNodes &&
          node.childNodes[0]
        ) {
          walk(node, fn);
        }
      } while ((node = node.nextSibling));
    }

    return div.innerHTML;
  }

  static getExt(item) {
    if (!item || !item.content || !item.content.content) {
      return "";
    }

    if (
      item.content.typeString !== "file" &&
      item.content.typeString !== "video"
    ) {
      return item.content.typeString;
    }

    var arr = item.content.content.split(".");
    return arr[arr.length - 1];
  }

  static saveLocalDBCurseDrops(drop, course, classId) {
    var key = DB_CURSE_KEY + course.courseID + "_" + classId;
    var courseLocalData = localStorage.getItem(key);

    if (!courseLocalData) {
      courseLocalData = {};
    } else {
      courseLocalData = JSON.parse(courseLocalData);
    }
    var itemDropDb = {};

    if (drop.items.length === 0) {
      itemDropDb.type = "ONLY_DATA";
    } else {
      itemDropDb.type = "ITENS";
      itemDropDb.items = drop.items;
    }

    courseLocalData[drop.id] = itemDropDb;

    localStorage.setItem(key, JSON.stringify(courseLocalData));
  }

  static getLocalDBCurseDrops(course, classId) {
    if (!course) {
      return null;
    }
    var key = DB_CURSE_KEY + course.courseID + "_" + classId;

    var data = localStorage.getItem(key);
    if (data === null) {
      return null;
    }
    data = JSON.parse(data);
    return data;
  }

  static getLocalDropAnsewer(drop, localDataCourse) {
    if (localDataCourse === null) {
      return null;
    }
    var data = localDataCourse[drop.id];
    if (data) {
      return data;
    }
    return null;
  }

  static formatInTimeZone = (date, fmt) => {
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
    return format(utcToZonedTime(date, timeZone),fmt,{ timeZone });
  }

  static convertDatetimeByTimeZone(datetime) {

    const time = datetime;
    const parsedTime = parseISO(time);
    const formattedTime = this.formatInTimeZone(parsedTime, "dd/MM/yyyy kk:mm:ss");

    return formattedTime

  }

  static validateCpf(value) {
    let cpf = value.replace(/[^\d]+/g,'');	
    if(cpf == '') return false;	

    // Elimina CPFs invalidos conhecidos	
    if (cpf.length != 11 || 
      cpf == "00000000000" || 
      cpf == "11111111111" || 
      cpf == "22222222222" || 
      cpf == "33333333333" || 
      cpf == "44444444444" || 
      cpf == "55555555555" || 
      cpf == "66666666666" || 
      cpf == "77777777777" || 
      cpf == "88888888888" || 
      cpf == "99999999999")
        return false;		

    // Valida 1o digito	
    let add = 0;	
    for (let i=0; i < 9; i ++)		
      add += parseInt(cpf.charAt(i)) * (10 - i);	
      let rev = 11 - (add % 11);	
      if (rev == 10 || rev == 11)		
        rev = 0;	
      if (rev != parseInt(cpf.charAt(9)))		
        return false;		

    // Valida 2o digito	
    add = 0;	
    for (let i = 0; i < 10; i ++)		
      add += parseInt(cpf.charAt(i)) * (11 - i);	
    rev = 11 - (add % 11);	
    if (rev == 10 || rev == 11)	
      rev = 0;	
    if (rev != parseInt(cpf.charAt(10)))
      return false;		
      
    return true;
  }

  static validateBirthDate(value) {
    let birthDate = value
    
    if (birthDate == '') return false;	

    if (birthDate.length != 10) return false

    let splitDate = birthDate.split('/')
    if (splitDate.length != 3) return false

    if ((splitDate[0].length != 2) || (splitDate[1].length != 2) || (splitDate[2].length != 4)) {
        return false;
    }

    let dia = parseInt(splitDate[0],10);
    let mes = parseInt(splitDate[1],10)-1; //O JavaScript representa o mês de 0 a 11 (0->janeiro, 1->fevereiro... 11->dezembro)
    let ano = parseInt(splitDate[2],10);
    let novaData = new Date(ano, mes, dia);
    let hoje = new Date();

    if (novaData > hoje) return false

    if ((novaData.getDate() != dia) || (novaData.getMonth() != mes) || (novaData.getFullYear() != ano)) {
        return false;
    } else {
        return true;
    }
  }

  static validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };
}
