import { lazy } from 'react'

const LoginScreen = lazy(() => import('../screen/Login'))
const ForgotScreen = lazy(() => import('../screen/ForgotPassword'))
const SignOnContainer   = lazy(() => import("../screen/SignOnContainer"))

const dashboard = [
  {
    path: '/login',
    component: LoginScreen,
    exact: true
  },{
    path: '/forgot',
    component: ForgotScreen,
    exact: true
  },{
    path: "/sso",
    component: SignOnContainer,
    exact: false
  }
]

export default dashboard
