import React, {Component} from 'react'
import './style.scss'

export default class MenuLabel extends Component {
    render() {
        return (
            <span 
                className="menu-label"
                style={{
                    color: this.props.color,
                    fontWeight: this.props.fontWeight,
                }}
            >{this.props.label}</span>
        )
    }
}
