import { lazy } from 'react'

const ChatBotLogin = lazy(() => import('../screen/ChatBotLogin'))

const dashboard = [
  {
    path: '/chatbotlogin',
    component: ChatBotLogin,
    exact: true
  }
]

export default dashboard
