import { combineReducers } from 'redux'
import { changeReducer } from './clickReducer'
import { teamReducer } from './teamReducer'
import { userReducer } from './userReducer'
import { authReducer } from './authReducer'
import { replyReducer } from './replyReducer'

export const Reducers = combineReducers({
  changeState: changeReducer,
  teamState: teamReducer,
  userState: userReducer,
  authState: authReducer,
  replyState: replyReducer
})
