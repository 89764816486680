import styled from 'styled-components'

const ContainerStyled = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`

const LayoutStyled = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding-top: 59px;

  @media(min-width: 0px) and (orientation: landscape) {
    padding-top: 51px;
  }

  @media(min-width: 600px) {
    padding-top: 67px;
  }
`

export {
  ContainerStyled,
  LayoutStyled
}
