import { createTheme } from '@material-ui/core/styles'
import { getConfigSync } from "services/config/ConfigService";

var config = getConfigSync();
var color2 = "#333";

if (config && config.color2) {
  color2 = config.color2;
}


const theme = createTheme({
  palette: {
    primary: {
      main: '#000'
    },
    secondary: {
      main: color2,
      light: '#86D14D',
      dark: '#295805',
      contrastText: '#FFF'
    },
    error: {
      main: '#E25335'
    },
    warning: {
      main: '#FFA012',
      contrastText: '#FFF'
    },
    success: {
      main: '#59C00B',
      contrastText: '#FFF'
    }
  },
  typography: {
    border: 'rgba(0, 0, 0, 0.42)',
    label: '#404040',
    useNextVariants: true
  }
})

export default theme
