import { Service } from "services/Service";
import logo from "assets/image/logo.png";
import Util from "services/Util";

export const CONFIG_KEY = "@beedooconfigkey";

export const getDefaultObject = ()=> {
  return {
    color1: '#eec316',
    color2: '#e77e24',
    colorDisabled: '#999',
    logo
  };
};

export const mergeDefaultConfig = (configObj, defaultObj)=> {
  Object.keys(defaultObj).forEach(def=> configObj[def] = configObj[def] || defaultObj[def]);
  return configObj;
};

export const getConfig = async () => {
  try {
    if (JSON.parse(localStorage.getItem(CONFIG_KEY))) {
      return JSON.parse(localStorage.getItem(CONFIG_KEY))
    }
    const service = new Service(null,true);
    const util = new Util();
    const res = await service.getWaiting( "/team/"+util.getSubDomain() );
    if (!res || !res.team) return getDefaultObject();

    localStorage.setItem( CONFIG_KEY, JSON.stringify(mergeDefaultConfig(res.team, getDefaultObject())));
    return JSON.parse(localStorage.getItem(CONFIG_KEY));
  } catch (error) {
    return getDefaultObject();
  }
};

export function getConfigSync() {
  if (JSON.parse(localStorage.getItem(CONFIG_KEY))) {
    return JSON.parse(localStorage.getItem(CONFIG_KEY))
  } 
  return getDefaultObject();
}

export const getVisualIdentity = () => {
  try {
    return JSON.parse(localStorage.getItem("VISUAL_IDENTITY"));
  } catch (error) {
    console.error(error);
  }
};

