import { createGlobalStyle } from 'styled-components'
import './font.css'

const globalStyle = createGlobalStyle`
  html {
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  }

  html,
  body,
  #root {
    width: 100%;
    height: 100%;
    display: flex;
    background-color:#FFF;
    font-family: "HelveticaNormal", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  }

  .hide {
    display: none!important;
  }

  .invisible {
    visibility: hidden!important;
    pointer-events: none!important;
  }  

  .full-width {
    width: 100%;    
  }

  .flex {
    display: flex;
  }

  .flex-1 {
    flex: 1;
  }

  .overflow-y {
    overflow-y: auto;
  }

  .text-center {
    text-align: center!important;
  }

  .p-padding-bottom-1 {
    padding-bottom: 10px!important;
  }

  .p-padding-bottom-2 {
    padding-bottom: 20px!important;
  }

  .p-margin-top-1 {
    margin-top: 10px!important;
  }

  .p-margin-top-2 {
    margin-top: 20px!important;
  }

  .p-margin-bottom-1 {
    margin-bottom: 10px!important;
  }

  .p-margin-bottom-2 {
    margin-bottom: 20px!important;
  }
`

export default globalStyle