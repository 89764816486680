import { ACTION_TEAM_VISUAL_IDENTITY, ACTION_TEAM_CONFIG } from '../actions/actionTypes'

const initialState = {
  config: {},
  visualIdentity: {
    white_label: 0,
    bot_image: ''
  }
}

export const teamReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTION_TEAM_VISUAL_IDENTITY:
      return {
        ...state,
        visualIdentity: action.visualIdentity
      }
    case ACTION_TEAM_CONFIG:
      return {
        ...state,
        config: action.config
      }
    default:
      return state
  }
}
