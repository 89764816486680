import { Service } from "services/Service";

function getDimensions () {
    return {
        manufacturer: window.navigator.appName,
        model: window.navigator.appCodeName,
        width: window.screen.width,
        height: window.screen.height,
        location: window.location.href,
    };
}

export default function reportError(errorMsg) {
    if(sessionStorage.getItem('logSent')) return;
    sessionStorage.setItem('logSent', true);
    const service = new Service(null, true);
    service.postWaiting(`/api/v1/log`, {
        body: JSON.stringify({ detail: getDimensions(), errorMsg })
    });
}