import axios from "axios";
import Util from "./Util";
import { logout, getToken, TOKEN_KEY, REFRESH_TOKEN_KEY, REMEMBER_ME } from "services/Auth";

const ApiService = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    'Content-Type': 'application/json'
  },
})

ApiService.interceptors.request.use(function (config) {
  const token = getToken();
  config.headers.Authorization = `Bearer ${token}`;

  return config;
});

ApiService.interceptors.response.use((response) => {
  return response;
}, async (error) => {
  if (error.message === 'Network Error') return;

  const acessToken = getToken();
  const rememberMeEnabled = localStorage.getItem(REMEMBER_ME);
  if (rememberMeEnabled == 'true' && error.response.status === 401 && acessToken && error.config.url !== '/refreshToken') {
    const response = await refreshToken();
    if (response) return ApiService.request(error.config);
  }

  if (error.response.status === 401 ) {
    logout();
    return window.location = '/login'
  }

  return error.response;
});

const refreshToken = async () => {
  const util = new Util();
  const refreshToken = localStorage.getItem(REFRESH_TOKEN_KEY);
  const body = {
    subdomain: util.getSubDomain(),
    refreshToken
  };

  try {
    const response = await ApiService.post('/refreshToken', body);
    if (response.status === 200) {
      localStorage.setItem(TOKEN_KEY, response.data.token);
      localStorage.setItem(REFRESH_TOKEN_KEY, response.data.refreshToken);
      return true;
    }
  } catch (err) {
    return false;
  }
};

export default ApiService;
