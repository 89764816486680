import React from "react";

export default class extends React.Component {
    UNSAFE_componentWillMount(){
        if ('serviceWorker' in navigator) {
            navigator.serviceWorker.register('service-worker.js')
              .then(function(reg){
             }).catch(function(err) {
            });
         }
    }

    render(){
        return null;
    }
}


