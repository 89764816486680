import { ACTION_REPLY } from '../actions/actionTypes'

export const replyInitialState = {
  isReplying: false,
  commentId: null,
  receiverName: null,
  replyCounts:{},
  repliesAddedCounts:{}
}

export const replyReducer = (state = replyInitialState, action) => {
  switch (action.type) {
    case ACTION_REPLY:
      return {
        ...state,
        isReplying: action.isReplying,
        commentId: action.commentId,
        receiverName: action.receiverName,
        replyCounts: action.replyCounts,
        repliesAddedCounts: action.repliesAddedCounts
      }
    default:
      return state
  }
}
