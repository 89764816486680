import { ACTION_PAGE } from '../actions/actionTypes'

const initialState = {
  page: 'SplashScreen'
}

export const changeReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTION_PAGE:
      return {
        ...state,
        page: action.page
      }
    default:
      return state
  }
}
