import React from 'react';
import './styles.scss'

export default function NotificationAlert({ notification }) {
    return (
        notification > 0 ?
            <span className="notification-circle" >
                { notification > 9 ? '+9' : notification }
            </span>
            : null
    )
}