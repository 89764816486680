import styled from "styled-components";

export const Container = styled.div`
    display: ${props => (props.show ? 'block' : 'none')};
    background-color: #FFF;
    position: fixed;
    bottom:0;
    width:100%;
    height:53px;
    z-index: 4;
`;

export const ShadowContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
`;

export const IconWrapper = styled.div`
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 100%;
`;

export const IconContainer = styled.div`
    position: relative;
    min-width: 52px;
`;

export const NotificationIconStyled = styled.div`
    position: relative;
`