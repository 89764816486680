import React, { Suspense, memo } from "react";
import { Switch, withRouter } from "react-router-dom";
import routes from "../../routes";
import Loading from "components/Loading";
import AppRoute from "./AppRoute";
import { ContainerStyled } from "./style";
import BottomMenu from "components/BottomMenu/BottomMenu";
import { isAuthenticated } from "services/Auth";
import { isEmbeded } from "services/config/EmbededService";
import { Redirect } from "react-router-dom";
import AddToHomeScreemServiceWorker from './AddToHomeScreemServiceWorker'
import pathAfterLogin from "services/pathAfterLogin";

import { connect } from 'react-redux'

const Main = withRouter(props => {
  const {pathname} = props.location;
  const isUserAuthenticated = isAuthenticated();
  pathAfterLogin.setPath(pathname, isUserAuthenticated)
  return (
    <ContainerStyled>
      <AddToHomeScreemServiceWorker  />
      <Suspense fallback={<Loading />}>
        { pathname === "/" ||
        pathname === "/chat" ||
        pathname === "/login" ||
        pathname === "/start" ||
        pathname === "/sso" ||
        pathname === "/forgot" ||
        isEmbeded() ||
        (pathname.match(/^\/sharedWiki\/wiki\/[0-9]+\/[0-9]+(.+)?/) && !isUserAuthenticated) ||
        pathname === "/chatbot2" ? null : (
          <BottomMenu
            selected={pathname}
            color1={props.teamConfig.color2}
            color2={props.teamConfig.colorDisabled}
            hasBeebot={props.teamConfig.hasBeebot === 'true'}
            teamVisualIdentity={props.teamVisualIdentity}
          />
        )}
        {!isUserAuthenticated &&
        (pathname !== "/login" &&
          pathname !== "/sso" &&
          pathname !== "/start" &&
          pathname !== "/forgot" &&
          !pathname.match(/^\/sharedWiki\/wiki\/[0-9]+\/[0-9]+(.+)?/) &&
          pathname !== "/") ? (
          <Redirect to={{ pathname: "/", state: { from: props.location } }} />
        ) : (
          <Switch>
            {routes.map(route => (
              <AppRoute key={route.path} {...route} />
            ))}
          </Switch>
        )}
      </Suspense>

    </ContainerStyled>
  )});

const mapStateToProps = (state) => ({
  teamVisualIdentity: state.teamState.visualIdentity,
  teamConfig: state.teamState.config
})

export default memo(connect(mapStateToProps, null)(Main))
