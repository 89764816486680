import React from 'react'
import PropTypes from 'prop-types'
import { LoadingStyled, LoadingIcon } from './style'

const Loading = ({ full, transparent }) => (
  <LoadingStyled full={full} data-testid="loading" transparent={transparent}>
    <LoadingIcon />
  </LoadingStyled>
)

Loading.propTypes = {
  full: PropTypes.bool
}

export default Loading
