
const EMBEDED_KEY = "@beedooembededkey";

export const isEmbeded = () => {
  if (window.location.href.indexOf('embeded=1') > 1) {
    localStorage.setItem(EMBEDED_KEY, 1);
  }
  if (window.location.href.indexOf('embeded=0') > 1) {
    localStorage.removeItem(EMBEDED_KEY);
  }
  try {
    return Boolean(localStorage.getItem(EMBEDED_KEY));
  } catch (error) {
    return false;
  }
}