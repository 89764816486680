import React, {useEffect, useState} from "react";
import bottonIcon from "assets/image/botton_menu.png";
import LinkTo from "components/LinkTo";
import AppIcons from "components/AppIcons/index";
import MenuLabel from './MenuLabel'
import NotificationAlert from '../BottomMenu/NotificationAlert/index';
import ProfileService from "services/profile/ProfileService";
import eventBus from "commons/utils/EventBus";

import {
  Container,
  IconContainer,
  ShadowContainer,
  IconWrapper,
  NotificationIconStyled
} from "./BottomMenu_style.js";
import './BottomMenu.scss'

const BottomMenu = ({
  color1,
  color2,
  hasBeebot,
  selected,
  teamVisualIdentity: {
    bot_image: botImage,
    custom_modules: customModules,
    white_label: whiteLabel,
  },
}) => {
  const [nameCourse, setNameCourse] = useState('Quiz');
  const [courses, setCourses] = useState(0);
  const [lightningTest, setLightningTest] = useState(0);
  const [show, setShow] = useState(true);
  const [doubleClickBlock, setDoubleClickBlock] = useState(true);
  const [timeoutId, setTimeoutId] = useState(null);
  useEffect(() => {
    setCourseName()
    getNotificationData()
    setShowFooter()
    setDoubleClickBlock(true)
    eventBus.on("quizpopanswered", () => {
      setLightningTest(lightningTest - 1);
    });
  }, []);
  const refreshPage = (page) => {
    clearTimeout(timeoutId);
    const idTimeout = setTimeout(() => setDoubleClickBlock(false), 5000);
    setTimeoutId(idTimeout);
    if (doubleClickBlock) return;
    
    const userSelectedSameIcon = page === selected;

    setDoubleClickBlock(true);
    if (userSelectedSameIcon && !doubleClickBlock) return window.location.reload(false);
  }
  const setShowFooter = () => {

    eventBus.on("quizpopanswered", () => {
      setLightningTest(lightningTest - 1);
    });
  
  }
  const setCourseName = () => {
    if (customModules && customModules.length > 0) {
      setNameCourse(customModules[0].module_name);
    }
  }
  const getNotificationData = async () => {
    const profile = new ProfileService(true)
    const dataNotifications = await profile.getNotifications()
    setCourses(dataNotifications.courses[0].value);
    setLightningTest(dataNotifications.lightningTest[0].value);
  }
  const icon = ({
    click,
    icon,
    label,
    notificationAlert,
    pageName,
    to,
  }) => {
    const pageSelected = selected.split('/')[1]
    const isSelected = pageName.some(page => page === pageSelected)
    const color = isSelected ? color1 : color2
    const fontWeight = isSelected ? '500' : 'normal'
    return (
      <LinkTo onClick={click} to={to} className="menu-item">
        <IconContainer>
          <div className="menu-icon-container">
            <NotificationIconStyled>
              <NotificationAlert
                notification={notificationAlert}
              />
              <AppIcons icon={icon} color={color} size={28} />
            </NotificationIconStyled>
            <MenuLabel label={label} color={color} fontWeight={fontWeight}/>
          </div>
        </IconContainer>
      </LinkTo>
    );
  }
  const beebotIcon = () => {
    if (!hasBeebot) return false;
    const isWhiteLabel = parseInt(whiteLabel) === 1;
    const isDefaultImageBot = botImage.indexOf("BOT.png") !== -1;
    const beetBotClassName = isDefaultImageBot ? "beebotMenuWhiteLabelDefault" : 'beebotMenuWhiteLabel'
    return (
      <LinkTo onClick={() => refreshPage("/chat")} to={"/chat"} className="menu-item">
        <div className="menu-icon-container">
          <img
            src={isWhiteLabel ? botImage : bottonIcon}
            alt="Logo" 
            className={isWhiteLabel ? beetBotClassName : "beebotMenuNoWhiteLabel"}
            style={{filter: ""}}
          />
        </div>
      </LinkTo>
    );
  }
  const getLabel = (maxLenghtLabel, nameCourse) => {
    return ((nameCourse).length > maxLenghtLabel) ? (((nameCourse).substring(0, maxLenghtLabel - 3)) + '...') : nameCourse
  }
  const feedIconParams = {
    click: () => refreshPage('/home'),
    icon: 'home',
    label: 'Feed',
    pageName: ['home', 'feed'],
    to: '/home',
  }
  const wikiIconParams = {
    click: () => refreshPage('/wiki'),
    icon: 'lightning',
    label: 'Wiki',
    pageName: ['wiki', 'postOpen'],
    to: '/wiki',
  }
  const lightningTestIconParams = {
    click: () => refreshPage("/quizpop"),
    icon: 'quizpop',
    label: "Teste Rel...",
    notificationAlert: lightningTest,
    pageName: ['quizpop'],
    to: "/quizpop",
  }
  const quizIconParams = {
    click: () => refreshPage("/quiz"),
    icon: 'quiz',
    label: getLabel(11, nameCourse),
    notificationAlert: courses,
    pageName: ['quiz'],
    to: "/quiz",
  }

  return (
    <Container show={show}>
      <ShadowContainer className="shadow2">
        <IconWrapper className="maxWidth">
          {icon(feedIconParams)}
          {icon(wikiIconParams)}
          {beebotIcon()}
          {icon(lightningTestIconParams)}
          {icon(quizIconParams)}
        </IconWrapper>
      </ShadowContainer>
    </Container>
  );
}

export default BottomMenu;
