
import React from "react";
import { Link } from "react-router-dom";

const LinkTo = ({
  onClick,
  style,
  className,
  to,
  children,
}) => {
    return (
      <Link 
        onClick={onClick}
        style={style}
        className={className}
        to={to}
      >
        {children}
      </Link>
    )
}

export default LinkTo;
