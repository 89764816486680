import { Service } from "services/Service";
import { getToken } from "services/Auth";

export default class ProfileService extends Service {
  history;
  service;

  async getUserBalance() {
    const tokeJw = this.parseJwt(getToken());
    const data = await this.getWaiting(`/api/v1/users/profile/${tokeJw.id}`);
    return data.data.user.score;
  }

  parseJwt(token) {
    // todo - instalar jwt e usar corretamente a forma de parse
    // todo - outros lugares copiam e colam a função, mapear e usar sempre o static
    return ProfileService.parseJWT(token);
  }

  static parseJWT(token) {
    const base64Url = token.split(".")[1];
    const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    const jsonPayload = decodeURIComponent(
        atob(base64)
            .split("")
            .map(c=> "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2))
            .join("")
    );

    return JSON.parse(jsonPayload);
  }

  async getProfile() {
    const tokeJw = this.parseJwt(getToken());
    const data = await this.getWaiting(`/api/v1/users/profile/${tokeJw.id}`);
    return data.data;
  }
  
  async getUserProfileInfo(){
    const data = await this.getWaiting(`/api/v1/users/profile-info`);
    return data.data;
  }

  async getMedals() {
    const tokenJWT = this.parseJwt(getToken())
    const data = await this.getWaiting(`/api/v1/users/medal/counts/${tokenJWT.id}`)
    return data.data;
  }

  async getMedalReceivements({medalId}) {
    const tokenJWT = this.parseJwt(getToken())
    const data = await this.getWaiting(`/api/v1/users/medal/receivements/${tokenJWT.id}/${medalId}`)
    return data.data;
  }

  async getMedalCertificate({learnClassUserId}){
    const data = await this.getWaiting(`/api/v1/users/medal/certificate/${learnClassUserId}`)
    return data.data[0].attributes;
  }

  async getNotifications() {
    const data = await this.getWaiting(`/api/v1/notifications`)
    return data.data;
  }

  async getTermsOfUse() {
    const data = await this.getWaiting(`/api/v1/user/getTermsOfUse`)
    return data.data;
  }

  async insertBeetcoins(scoreObj) {
    const tokenJWT = this.parseJwt(getToken());
    return await this.put(`/api/v1/users/profile/score/${tokenJWT.id}`, {
      interactionCode: scoreObj.interactionCode, 
      postId: scoreObj.postId,
      description: scoreObj.description, 
      detail: scoreObj.detail,
      target: scoreObj.target
    });
  }

  insertAcceptTermsOfUse() {
    const tokenJWT = this.parseJwt(getToken());
    return this.put(`/api/v1/user/acceptTermsOfUse`, {
      userId: tokenJWT.id
    });
  }

  async updateUser(data) {
    const tokenJWT = this.parseJwt(getToken());
    return await this.put(`/admin/api/v1/users/${tokenJWT.id}`, data)
  }

};
