import React from 'react'
import { BrowserRouter } from 'react-router-dom'
import CssBaseline from '@material-ui/core/CssBaseline'
import GlobalStyle from 'commons/styles/global-style'
import 'react-toastify/dist/ReactToastify.css'
import Main from 'screen/Main'

const App = () => (
  <>
    <CssBaseline />
    <GlobalStyle />
    <BrowserRouter>
      <Main />
    </BrowserRouter>
  </>
)

export default App
